<template>
  <v-card class="pa-5">
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <m-widget-container-form v-model="value" />
      <field-card label="Video">
        <asset-manager
          v-model="value.src"
          asset-type="video"
        />
      </field-card>
      <field-card label="Player Options">
        <v-row>
          <v-switch
            v-for="option in videoOptions"
            :key="option"
            v-model="value[option]"
            class="ma-2 mr-4"
            :label="option | capitalizeFirstLetter"
            required
          />
        </v-row>
      </field-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MVideoForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null,
    upload: false,
    videoOptions: [
      'fitWidth',
      'autoplay',
      'loop',
      'controls',
      'muted'
    ]
  })
}
</script>
